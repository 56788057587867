import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  getOpenTickets,
  getClosedTickets,
  onSendMessage,
  onSendKnowbase,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
  toggleKnowbaseEditMode,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
// import UserHead from "../ConversationUser/UserHead";
import UserHead from "./KnowbaseHead";
// import Conversation from "../ConversationUser/Conversation";
// import ChatInputSection from "../ConversationUser/ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs } from "../../../data/index";

import  KnowbaseContent  from "./Content"

interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
// const Index = (props: IndexProps) => { 
  const [isEditing, setIsEditing] = useState<boolean>(false); 
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    knowbaseDetails,
    isKnowbaseEditMode,
    isKnowbaseSent,
    chatUserDetails,
    chatUserConversations,
    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    selectedChat,
    isUpdateReceived,
    isOpenUserDetails,
    isTriggerAddNote,
  } = useAppSelector(state => ({
    knowbaseDetails: state.Knowbases.knowbaseDetails,
    isKnowbaseEditMode: state.Knowbases.isKnowbaseEditMode,
    isKnowbaseSent: state.Knowbases.isKnowbaseSent,
    chatUserDetails: state.Chats.chatUserDetails,
    chatUserConversations: state.Chats.chatUserConversations,
    isUserMessageSent: state.Chats.isUserMessageSent,
    isMessageDeleted: state.Chats.isMessageDeleted,
    isMessageForwarded: state.Chats.isMessageForwarded,
    isUserMessagesDeleted: state.Chats.isUserMessagesDeleted,
    isImageDeleted: state.Chats.isImageDeleted,
    selectedChat: state.Chats.selectedChat,
    isUpdateReceived: state.Chats.isUpdateReceived,
    isOpenUserDetails: state.Chats.isOpenUserDetails,
    isTriggerAddNote: state.Chats.isTriggerAddNote,
  }));

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
  const { userProfile } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: any) => {
    console.log('==selected chat==', selectedChat);
    let params: any = {
      text: data.text && data.text,
      time: new Date().toISOString(),
      image: data.image && data.image,
      ticketid: selectedChat,
      attachments: data.attachments && data.attachments,
      meta: {
        receiver: chatUserDetails.id,
        sender: userProfile.uid,
      },
    };
    if (replyData && replyData !== null) {
      params["replyOf"] = replyData;
    }

    console.log('=params=', params)

    dispatch(onSendKnowbase(knowbaseDetails.id,params));
    // if (!isChannel) {
    //   setTimeout(() => {
    //     dispatch(receiveMessage(selectedChat));
    //   }, 1000);
    //   setTimeout(() => {
    //     dispatch(readMessage(selectedChat));
    //   }, 1500);
    //   setTimeout(() => {
    //     dispatch(receiveMessageFromUser(selectedChat));
    //   }, 2000);
    // }
    // setReplyData(null);
  };

  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted
    ) {
      // dispatch(getChatUserConversations(selectedChat));
    }

    if (isUpdateReceived) {
      // dispatch(getChatUserConversations(selectedChat));
      // dispatch(getOpenTickets());
      // dispatch(getClosedTickets());
    }


  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
    isUpdateReceived,
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.id));
  };

  const onEnableKnowbaseEditMode = () => {
    dispatch(toggleKnowbaseEditMode(true));
  };



  const [formData, setFormData] = useState({
    name: '',
    text: 'in here',
    images: [],
    files: []
  });

// const handleInputChange = (content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor) => {
//   const { value } = delta;
//   setFormData((prevState) => ({
//     ...prevState,
//     text: value,
//   }));
// };
  const handleInputChange = (name: string, value: any) => {
    // const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));

    // console.log('am here name');
  };

  useEffect(() => {
    if (knowbaseDetails && knowbaseDetails.text) {
      // setText(knowbaseDetails.text);
      setFormData((prevState) => ({
        ...prevState,
        ['text']: knowbaseDetails.text
      }));      
    }
  }, [knowbaseDetails]);


  useEffect(() => {
    if (isKnowbaseSent) {
      dispatch(toggleKnowbaseEditMode(false));    
    }
  }, [isKnowbaseSent]);


  const onSubmit = () => {
    // console.log('---in submiit---');
    let data: any = {};
    if (formData.text) {
      data["text"] = formData.text;
    }
    if (formData.images && formData.images.length) {
      const imgs = (formData.images || []).map((i: any, key: number) => {
        const src = URL.createObjectURL(i);
        return {
          id: key + 1,
          downloadLink: i,
          src: src,
        };
      });
      data["image"] = imgs;
    }

    if (formData.files && formData.files.length) {
      const fs = (formData.files || []).map((f: any, key: number) => {
        const src = URL.createObjectURL(f);
        return {
          id: key + 1,
          name: f.name,
          downloadLink: f,
          src: src,
          desc: f.size,
        };
      });
      data["attachments"] = fs;
    }

    // alert(data);
    // console.log('the data', data);

    // setText("");
    // setImages(null);
    // setFiles(null);
    onSend(data);
  };


  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
        isOpenUserDetails={isOpenUserDetails} 
        isTriggerAddNote={isTriggerAddNote}
        selectedChat={selectedChat}
        onEnableKnowbaseEditMode={onEnableKnowbaseEditMode}
        isKnowbaseEditMode={isKnowbaseEditMode}
        onSubmit={onSubmit}


      />
{/*      <Conversation
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
      />*/}
      <KnowbaseContent
        knowbaseDetails={knowbaseDetails}
        onSend={onSend}
        isKnowbaseEditMode={isKnowbaseEditMode}
        formData={formData}
        handleInputChange={handleInputChange}

      />

{/*
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
 */}     
    </>
  );
};

export default Index;
