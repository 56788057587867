import React, { useState } from "react";
import classnames from "classnames";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//utils
import { DivideByKeyResultTypes } from "../../../utils";

// interfaaces
// import { ContactTypes } from "../../../data/contacts";
import { TicketTypes } from "../../../data/tickets";


interface ContactItemProps {
  contact: TicketTypes;
  onSelectChat: (id: string | number, isChannel?: boolean) => void;
  selectedTicket: string | number;
}
const ContactItem = ({ contact, onSelectChat, selectedTicket }: ContactItemProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const fullName = `${contact.firstName} ${contact.lastName}`;

  const shortName = `${contact.firstName.charAt(0)}${contact.lastName.charAt(
    0
  )}`;

  const time_spent = `${contact.created_since}`;

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));

  const isSelectedTicket: boolean =
    selectedTicket && selectedTicket === contact.id ? true : false;

  return (
    <li className={classnames({ active: isSelectedTicket })} onClick={() => onSelectChat(contact.id)}>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-2">
          <div className="avatar-xs">
            {contact.profileImage ? (
              <img
                src={contact.profileImage}
                alt=""
                className="img-fluid rounded-circle"
              />
            ) : (
              <span
                className={classnames(
                  "avatar-title",
                  "rounded-circle",
                  "font-size-10",
                  "text-uppercase",
                  colors[color]
                )}
              >
                {shortName}
              </span>
            )}
          </div>
        </div>
        <div className="flex-grow-1">

{/*  <h5 className="font-size-14 m-0">{fullName}</h5>
        
<div className="text-muted font-size-12 text-truncate">
<i className="ri-arrow-right-up-fill text-danger align-bottom me-1">  
</i>29 Aug, 2019, 09:529:52 AM
</div>*/}  

          <div className="flex-grow-1 overflow-hidden">
          <p className={`text-truncate mb-0 ${contact.user_waiting ? 'fw-semibold' : ''}`}>{fullName}</p>
          <div className="text-muted font-size-12 text-truncate">
          {time_spent}
          </div>
          </div>          
        
        </div>

{/*
        <div className="flex-shrink-0">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" className="text-mute">
              <i className="bx bx-dots-vertical-rounded align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">

              <DropdownItem
                className="d-flex align-items-center justify-content-between"
                href="#"
              >
                Edit <i className="bx bx-pencil ms-2 text-muted"></i>
              </DropdownItem>
              <DropdownItem
                className="d-flex align-items-center justify-content-between"
                href="#"
              >
                Block <i className="bx bx-block ms-2 text-muted"></i>
              </DropdownItem>
              <DropdownItem
                className="d-flex align-items-center justify-content-between"
                href="#"
              >
                Remove <i className="bx bx-trash ms-2 text-muted"></i>
              </DropdownItem>

            </DropdownMenu>
          </Dropdown>
        </div>

*/}
        
      </div>
    </li>
  );
};
interface CharacterItemProps {
  letterContacts: DivideByKeyResultTypes;
  index: number;
  onSelectChat: (id: string | number, isChannel?: boolean) => void;
  selectedTicket: string | number
}
const CharacterItem = ({
  letterContacts,
  index,
  onSelectChat,
  selectedTicket,
}: CharacterItemProps) => {

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number | null ) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  return (


        <div key={index} className="accordion-item">
          <div className="accordion-header" id={`heading${index}`}>
            <h5 className="mb-0">
              <button
                className={`accordion-button ${activeIndex !== index ? 'collapsed' : ''}`}
                type="button"
                onClick={() => toggleAccordion(index)}
                aria-expanded={activeIndex === index}
                aria-controls={`collapse${index}`}
              >
               {letterContacts.letter} ({letterContacts.data.length})
              </button>
            </h5>
          </div>

          <div
            id={`collapse${index}`}
            className={`collapse ${activeIndex === index ? 'show' : ''}`}
            aria-labelledby={`heading${index}`}
            data-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ul className="list-unstyled contact-list">
                {(letterContacts.data || []).map((contact: any, key: number) => (
                  <ContactItem
                    contact={contact}
                    key={key}
                    onSelectChat={onSelectChat}
                    selectedTicket={selectedTicket}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>


  );
};

export default CharacterItem;
