import { eventChannel, EventChannel } from 'redux-saga';
// import { take, put, call, fork, cancel } from 'redux-saga/effects';
import { takeEvery, fork, put, all, call, take  } from "redux-saga/effects";


import { getLoggedinUser } from "../../api/apiCore";

import config from "../../config";


import {
  showSuccessNotification,
  showErrorNotification,
  showActionNotification, 
  showMessageNotification, 
} from "../../helpers/notifications";

// import { eventChannel} from 'redux-saga';
// import ActionPattern from "redux-saga"
// import { ActionPattern, Buffer, Channel, GuardPredicate, Pattern, Task, Effect } from '@redux-saga/types'
import { ActionPattern } from '@redux-saga/types'
import { Action } from 'redux'

//actions
import {
  // getDirectMessages as getDirectMessagesAction,
  // getPausedTickets as getPausedTicketsAction,  
  // getClosedTickets as getClosedTicketsAction,
  // getOpenTickets as getOpenTicketsAction,  
  // getFavourites as getFavouritesAction,
  getTickets as getTicketsAction,
  getReceiveUpdate as getReceiveUpdateAction,
} from "../actions";


//===================================================

function initWebsocket() {  
  return eventChannel(emitter => {    
    console.log('==logined iser==', getLoggedinUser());
    const user = getLoggedinUser();
    if (!user) {
      return () => {};
    } 
        // const newSocket = new WebSocket('ws://localhost:8002/ws/chatapp/?token=0e9b9e0a7794582ac06f43b939e982bc23d2c824');
    // const wsUrl = `ws://localhost:8002/ws/chatapp/?token=${user.access}`;
    const wsUrl = `${config.WS_URL}?token=${user.access}`;
    // console.log('new url',wsUrl1);
    // console.log(wsUrl);
    const ws = new WebSocket(wsUrl )  ;  
    // const token  = 'thistoken';
//     const ws = new WebSocket(wsUrl, undefined, {
//     headers: {
//         Authorization: `Bearer ${token}`
//     }
// });

    ws.onopen = () => {
      ws.send(JSON.stringify({ type: 'authenticate', token: 'abcdefghij' }));
      console.log('Opening Websocket ...');

      // sendPing = () => {
      //   console.log('Sending Ping ...');
      // } 

      function sendPing() {
          if (ws && ws.readyState === WebSocket.OPEN) {
              // console.log('--Sending ping--');
              ws.send(JSON.stringify({ 
                  'cmd': 'ping'
              }));
          }
      }

      setInterval(sendPing, 1*60*1000);
      // JSON.stringify({ 
      //       'cmd': 'ping',
      //       'message':message
      //   })
      // ws.send(
      //   JSON.stringify({ 
      //         'cmd': 'authenticate',
      //         'message': 'hello',
      //     })
      //   );      

      // ws.send(
      //   JSON.stringify({ 
      //         'cmd': 'ping',
      //         'message': 'hello',
      //     })
      //   );
    }    
    ws.onerror = (error: any) => {
      console.log('WebSocket error ' + error);
      console.dir(error);
    }    
    ws.onmessage = (e) => {
      console.log('Message came back', e);
      let msg = null;
      try {
        msg = JSON.parse(e.data);
        console.log('==msg==', msg);
      } catch(e: any) {
        console.error(`Error parsing : ${e.data}`);
      }
      if (msg) {
        // console.log('===emitting action===');
        // return emitter(getOpenTicketsAction());
        // return emitter(getReceiveUpdateAction());
        // const { payload: book } = msg        
        const ws_event = msg.event
        switch (ws_event) {
          case 'pong':
            console.log('..pong..');
            break;
            // return emitter({ type: ADD_BOOK, book })
          // case 'REMOVE_BOOK':
            // return emitter({ type: REMOVE_BOOK, book })
          default:
            showMessageNotification('New Message');
            emitter(getReceiveUpdateAction());
            // dispatch(getChatUserConversations(selectedChat));
            // emitter(getTicketsAction());
            break;
            // nothing to do
        }
      }
    }    
    // unsubscribe function
    return () => {
      console.log('Socket off');
    }
  })
}

export function* watchWebsockets() {
  const channel: ActionPattern<Action<any>>  = yield call(initWebsocket);
  while (true) {
    const action: Action<any>  = yield take(channel);
    yield put(action);
  }
}

//=====================================================


function* websocketsSaga() {
  yield all([
    fork(watchWebsockets),
  ]);
}

export default websocketsSaga;