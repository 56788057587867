import React from 'react';
import { 
	LineChart, 
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer 
} from 'recharts';

interface ChartItemProps {
  title: string;
  datakey: string;
  // data: Array<{ name: string; uv: number; pv: number; amt: number }>;
  data: any[];
}

// const ChartItem: React.FC<ChartItemProps> = ({ title, datakey, data }) => {
const ChartItem = ({ title, datakey, data }: ChartItemProps) => {
  return (
    <>
      <h6>{title}</h6>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={data}>
          <CartesianGrid stroke="#e0dfdf" />
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default ChartItem;
